<template>
    <div>
        <div class="mt-4">
        <v-alert dense outlined :type="type">
            {{text}}
        </v-alert>
        </div>
    </div>
</template>

<script>
export default {
  name: "AlertDence",
  props: {
    text: String,
    type: {
      type: String,
      default: "info"
    },
  },
  data: () => ({

  })
};
</script>
