<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-col lg="11" sm="8" xl="7">
        <v-card class="elevation-4">
          <v-row>
            <v-col lg="7" class="d-none d-md-flex align-center justify-center pa-0">
              <v-card class="login-image fill-height" :style="{backgroundImage: `url(${logoImage})`}">
                <v-row justify="center">
                  <v-overlay
                      absolute
                  >
                    <div class="d-none d-sm-block">
                      <div class="d-flex align-center pa-10">
                        <div>
                          <h2
                              class="display-1 white--text font-weight-medium"
                          >Médicos de excelencia</h2>
                          <h6
                              class="subtitle-1 mt-4 white--text op-5 font-weight-regular"
                          >25 años brindando el mejor servicio en preparación para el exámen de residentado médico y 16
                            años
                            para internado y ENAM</h6>
                        </div>
                      </div>
                    </div>
                  </v-overlay>
                </v-row>
              </v-card>
            </v-col>
            <v-col lg="5">
              <div class="pa-7 pa-sm-12">
                <img src="@/assets/images/logo.png"/>
                <h2 class="font-weight-bold blue-grey--text text--darken-2">Sign in</h2>

                <v-form @submit.prevent="doLogin" ref="form">
                  <v-text-field
                      v-model="email"
                      :rules="[rules.email, rules.email]"
                      label="E-mail"
                      class="mt-4"
                      required
                      outlined
                      dense
                      @focus="loginError = false"
                  ></v-text-field>
                  <v-text-field
                      v-model="password"
                      :rules="[rules.required, rules.min(6)]"
                      label="Password"
                      required
                      outlined
                      dense
                      :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="passwordVisible ? 'text' : 'password'"
                      @click:append="passwordVisible = !passwordVisible"
                      @focus="loginError = false"
                  ></v-text-field>
                  <alert-dence v-show="loginError" :text="errorText" :type="'error'"></alert-dence>
                  <v-checkbox
                      class="ma-0"
                      v-model="rememberMe"
                      label="Remember me"
                  ></v-checkbox>
                  <v-btn
                      :disabled="!valid"
                      :loading="loading"
                      color="info"
                      class="mr-4 login-button"
                      block
                      type="submit"
                      x-large
                  >Log In
                  </v-btn>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {email, required, minLength} from '@/utils/rules.js'
import {mapActions} from "vuex";
import AlertDence from "@/components/vuetifyComponents/alerts/AlertDence";
import logoImage from "@/assets/images/login-background.png"

export default {
  name: "BoxedLogin",
  components: {
    AlertDence,
  },
  data: () => ({
    overlay: true,
    logoImage,
    valid: true,
    errorText: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
    loginError: false,
    loading: false,
    password: "",
    passwordVisible: false,
    rememberMe: false,
    email: "",
    rules: {
      email,
      required,
      min: minLength
    },
    checkbox: false
  }),
  computed: {},
  methods: {
    ...mapActions(['login',]),
    async doLogin() {
      if (this.$refs.form.validate()) {
        this.loading = true
        try {
          this.email = this.email.toLowerCase()
          const payload = {username: this.email, password: this.password}
          await this.login(payload)
          await this.$router.push({name: 'Inicio'})
        } catch (e) {
          this.loginError = true
          this.errorText = e.response.data.non_field_errors[0]
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
.login-button {
  width: 100%;
  height: 56px;
  background: transparent linear-gradient(270deg, #0583DA 0%, #1FC0D0 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 6px #00000040;
}

.login-image {
  background-size: cover;
  width: 100%;
  height: 100%;
}
</style>
